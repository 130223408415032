import React from "react";
import "./Contact.css";

export default function Contact() {
  return (
    <div className="Contact">
      <h1 className="Contact-title">Contact</h1>
      <a className="Contact-start" href=" " target="_blank" rel="noreferrer">
        <img
          src="/images/zuck-logo.png"
          alt="logo"
          className="Contact-start-icon"
        />
        <span>Zuck</span>
      </a>
      <div className="Contact-iconbtns">
        <a
          href="https://twitter.com/Zuckcoinnft"
          target="_blank"
          rel="noreferrer"
        >
          <img src="/images/Contact-twitter.svg" alt="twitter" />
        </a>
        <a href="https://t.me/zuckcoinnft" target="_blank" rel="noreferrer">
          <img src="/images/Contact-telegram.svg" alt="etherscan" />
        </a>
      </div>
      <h1 className="Contact-content">
        $Zuck coin has no association with Mark Zuckerberg. <br /> This token is
        simply paying homage to a meme we all love and recognize.
      </h1>
    </div>
  );
}
