import React, { useState, useEffect } from "react";
import "./Header.css";
import { HashLink } from "react-router-hash-link";

const navItems = [{ name: "HOME", path: "/" }];

export default function Header() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <header className="Header">
      <HashLink smooth to="/" className="Header-navbar-start">
        <img
          src="/images/zuck-logo.png"
          alt="logo"
          className="Header-navbar-start-icon"
        />
        {windowWidth > 600 && <span>Zuck</span>}
        <div className="Header-navbar-Link">
          {navItems.map((item, index) => (
            <HashLink
              key={index}
              smooth
              to={item.path}
              className="Header-navbar-item"
            >
              {item.name}
            </HashLink>
          ))}
        </div>
      </HashLink>
      <div className="Header-navbar-end">
        <span className="Header-navbar-btn">
          <a
            href="https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75"
            target="_blank"
            rel="noreferrer"
          >
            BUY Zuck
          </a>
        </span>
      </div>
    </header>
  );
}
