import React from "react";
import "./Tokenomics.css";

type TokenomicsProps = {
  id: string;
};

export default function Tokenomics({ id }: TokenomicsProps) {
  return (
    <div className="Tokenomics" id={id}>
      <div className="Main-images Tokenomics-image">
        <img
          src="/images/home3.svg"
          alt="tokenomics-pepe"
          className="Main-pepes Tokenomics-pepe"
        />
      </div>
      <div className="Tokenomics-container">
        <h1 className="Tokenomics-title">TOKENOMICS</h1>
        <h1 className="Tokenomics-subtitle">TOKENOMICS OF ZUCK</h1>
        <div className="Tokenomics-divider"></div>
        <h1 className="Tokenomics-content">
          Contract Address
          <br /> 0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75
          <br />
          <br />
          Total Supply
          <br /> 420,690,000,000,000
          <br />
          <br />
          No Taxes
          <br />
          <br />
          Security
          <br /> Ownership Renounced
          <br />
        </h1>
      </div>
    </div>
  );
}
