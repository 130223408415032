import React from "react";
import "./Home.css";
const btnItems = [
  {
    name: "BUY Zuck",
    path: "https://app.uniswap.org/#/swap?inputCurrency=ETH&outputCurrency=0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75",
  },
  {
    name: "DEXTOOLS",
    path: "https://www.dextools.io/app/en/ether/pair-explorer/0xe03536aeae13d0562b41f577d77990b9202f47fe?t=1708249610787",
  },
  {
    name: "CONTRACT",
    path: "https://etherscan.io/address/0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75",
  },
];

type HomeProps = {
  id: string;
};

export default function Home({ id }: HomeProps) {
  return (
    <div className="Home" id={id}>
      <div className="Main-images Home-image">
        <img
          src="/images/home1.svg"
          alt="home"
          className="Main-pepes Home-pepe"
        />
      </div>
      <div className="Home-container">
        <h1 style={{ fontSize: "2rem", fontWeight: 500, margin: 0 }}>
          Welcome To
        </h1>
        <h1
          id="second-heading"
          className="second-heading"
          style={{ fontSize: "7.2rem", fontWeight: 900, margin: 0 }}
        >
          Zuck
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 400, margin: 0 }}>
          Missed $Bonk?
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 400, margin: 0 }}>
          Here is your second chance
        </h1>
        <div className="Home-divider"></div>
        <div className="Home-btns">
          {btnItems.map((item, index) => (
            <span className="Home-btn" key={index}>
              <a href={item.path} target="_blank" rel="noreferrer">
                {item.name}{" "}
              </a>
            </span>
          ))}
        </div>
        <div className="Home-iconbtns">
          <a
            href="https://twitter.com/Zuckcoinnft"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-twitter.svg" alt="twitter" />
          </a>
          <a
            href="https://www.dextools.io/app/en/ether/pair-explorer/0xe03536aeae13d0562b41f577d77990b9202f47fe?t=1708249610787"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-dextools.svg" alt="dextools" />
          </a>
          <a
            href="https://etherscan.io/address/0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75"
            target="_blank"
            rel="noreferrer"
          >
            <img src="/images/home-etherscan.svg" alt="etherscan" />
          </a>
        </div>
        <h1
          style={{ fontSize: "1.6rem", fontWeight: 500, margin: "0 0 2rem 0" }}
        >
          Contract
        </h1>
        <h1 style={{ fontSize: "1.6rem", fontWeight: 500, margin: 0 }}>
          0x70323c9C0BF8F6C6B3d5B368339CA6AbfD948B75
        </h1>
      </div>
    </div>
  );
}
